<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
  <a-layout class="layout-default layout-sign-up" id="layout-default">
    <DefaultHeader></DefaultHeader>

    <a-layout-content>
      <div>
        <!-- Sign Up Image And Headings -->
        <div
          class="sign-up-header"
          style="background-image: url('/images/bg-signup.jpg')"
        >
          <div class="content">
            <h1 class="mb-5">Reset your password</h1>
            <p class="text-lg">
              You can your reset password for security reasons if you forget it.
            </p>
          </div>
        </div>
        <!-- / Sign Up Image And Headings -->

        <!-- Sign Up Form -->
        <a-card
          :bordered="false"
          class="card-signup header-solid h-full"
          :bodyStyle="{ paddingTop: 0 }"
        >
          <template #title>
            <h5 class="font-semibold text-center">Reset password</h5>
          </template>
          <a-form
            id="components-form-demo-normal-login"
            :model="form"
            class="login-form"
            @finish="resetPsd"
          >
            <a-form-item class="mb-10">
              <a-input-search
                v-decorator="[
                  'Email',
                  {
                    rules: [
                      { required: true, message: 'Please input your email!' },
                    ],
                  },
                ]"
                v-model:value="form.mail"
                placeholder="Email"
              >
                <template #enterButton>
                  <a-button
                    type="primary"
                    @click="sendEmail"
                    :disabled="timer"
                    block
                    class="login-form-button"
                  >
                    {{ !timer ? "GET CODE" : timer + "s" }}
                  </a-button>
                </template>
              </a-input-search>
            </a-form-item>
            <a-form-item class="mb-5">
              <a-input
                v-decorator="[
                  'code',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ],
                  },
                ]"
                v-model:value="form.code"
                placeholder="Code"
              >
              </a-input>
            </a-form-item>
            <a-form-item class="mb-5">
              <a-input
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ],
                  },
                ]"
                type="password"
                v-model:value="form.password"
                placeholder="Password"
              >
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-button
                html-type="submit"
                type="primary"
				:loading="loading"
                block
                class="login-form-button"
              >
                Reset
              </a-button>
            </a-form-item>
          </a-form>
          <p class="font-semibold text-muted text-center">
            Already have an account?
            <router-link to="/sign-in" class="font-bold text-dark"
              >Sign In</router-link
            >
          </p>
        </a-card>
        <!-- / Sign Up Form -->
      </div>
    </a-layout-content>

    <DefaultFooter></DefaultFooter>
  </a-layout>
</template>

<script setup>
import DefaultHeader from "@/components/Headers/DefaultHeader";
import DefaultFooter from "@/components/Footers/DefaultFooter";
import { ref } from "vue";
// import { useStore } from "vuex";
import user from "@/api/modules/user";
import { to } from "@/utils/utils";
import { message } from "ant-design-vue";
// const store = useStore();
const getCodeFalg = ref(false);
const form = ref({
	username: "",
  mail: "",
  code: "",
  password: "",
});
const timer = ref(false);
const code = ref(null);
const loading = ref(false);
const sendEmail = async () => {
  const [err, res] = await to(user.sendMail(form.value.mail));
  if (err) {
    message.error(err.message);
    return;
  }
  getCodeFalg.value = true;
  timer.value = 60;
  const time = setInterval(() => {
    timer.value--;
    if (timer.value == 0) {
      timer.value = false;
      clearInterval(time);
    }
  }, 1000);
  code.value = res.data;
};
const resetPsd = async () => {
	loading.value = true;
  const [err, res] = await to(user.reset(form.value));
  if (err) {
    message.error(err.message);
	loading.value ==false;
    return;
  }
  
  if(res.code===200){
    message.success("密码重置成功", 1, () => {
      window.location.href = "/sign-in";
    });
  }
  
	loading.value ==false;
};
</script>

<style lang="scss">
.ant-input-search-button {
  border-radius: 0 6px 6px 0 !important;
}
</style>
